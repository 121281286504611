<template>
  <v-container v-if="apiLoaded">


<!--    <h1>-->
<!--      <span>Рабочий стол</span>-->
<!--      <a href="#" class="notifications"><span>3</span></a>-->
<!--    </h1>-->

    <section class="desktop" style="margin-bottom: 40px;">
      <div>
        <h2><span>Заявки</span>
          <router-link to="/applications/">
            <v-btn small>Все заявки</v-btn>
          </router-link>
        </h2>

        <v-tabs>
          <v-tab>Активные</v-tab>
          <v-tab>Завершенные</v-tab>


          <v-tab-item>
            <ul class="requests-list">
              <li v-if="activeApplications.length === 0">Нет заявок</li>
              <template v-else>
                <router-link
                    v-for="(app, appIndex) in activeApplications" :key="'myactiveapp_'+appIndex"
                    :to="'/tender/'+ app.notification.id +'/info?type=supmain%2Fnotifications'"
                >
                  <li>
                    <h4>{{ app.notification.customer.shortName || app.notification.customer.fullName }}</h4>
                    <p>{{ app.notification.theme }}</p>
                  </li>
                </router-link>
              </template>

            </ul>
          </v-tab-item>

          <v-tab-item>
            <ul class="requests-list">
              <li v-if="oldApplications.length === 0">Нет заявок</li>
              <template v-else>
                <router-link
                    v-for="(app, appIndex) in oldApplications" :key="'myoldapp_'+appIndex"
                    to="'/tender/'+ app.notification.id +'/info?type=supmain%2Fnotifications'">
                  <li>
                    <h4>{{ app.notification.customer.shortName || app.notification.customer.fullName }}</h4>
                    <p>{{ app.notification.theme }}</p>
                  </li>
                </router-link>
              </template>
            </ul>
          </v-tab-item>

        </v-tabs>

      </div>
      <div>
        <h2><span>Договоры</span>
          <router-link to="/contracts/">
            <v-btn small>Все договоры</v-btn>
          </router-link>
        </h2>
        <v-tabs>
          <v-tab>Активные</v-tab>
          <v-tab>Завершенные</v-tab>
          <v-tab-item>
            <ul class="requests-list">
              <li v-if="activeContracts.length === 0">Нет договоров</li>
              <template v-else>
                <router-link
                    :to="'/contract/'+ contract.id + '/info'"
                    v-for="(contract, contractIndex) in activeContracts"
                    :key="'myactivecontract_'+contractIndex">
                  <li>
                    <h4>{{ contract.customer.shortName || contract.customer.fullName }}</h4>
                    <p>{{ contract.theme }}</p>
                  </li>
                </router-link>
              </template>
            </ul>
          </v-tab-item>

          <v-tab-item>
            <ul class="requests-list">
              <li v-if="oldContracts.length === 0">Нет договоров</li>
              <template v-else>
                <router-link
                    :to="'/contract/'+ contract.id + '/info'"
                    v-for="(contract, contractIndex) in oldContracts"
                    :key="'myoldcontract_'+contractIndex">
                  <li>
                    <h4>{{ contract.customer.shortName || contract.customer.fullName }}</h4>
                    <p>{{ contract.theme }}</p>
                  </li>
                </router-link>
              </template>
            </ul>
          </v-tab-item>

        </v-tabs>
      </div>
<!--      <div v-if="userInOneOfRoles('ROLE_PARTNER', 'ROLE_ADMIN')">-->
<!--        <h2><span>Проекты</span>-->
<!--          <router-link to="/projects/">-->
<!--            <v-btn small>Все проекты</v-btn>-->
<!--          </router-link>-->
<!--        </h2>-->

<!--        <v-tabs>-->
<!--          <v-tab>Активные</v-tab>-->
<!--          <v-tab>Завершенные</v-tab>-->


<!--          <v-tab-item>-->
<!--            <p>Нет проектов</p>-->
<!--          </v-tab-item>-->

<!--          <v-tab-item>-->
<!--            <ul class="requests-list">-->
<!--              <li>Нет проектов</li>-->
<!--              &lt;!&ndash;						<li>&ndash;&gt;-->
<!--              &lt;!&ndash;							<h4>МИНПРОМТОРГ РОССИИ</h4>&ndash;&gt;-->
<!--              &lt;!&ndash;							<p>Комплекс услуг по проведению анализа отечественного и зарубежного опыта применения и</p>&ndash;&gt;-->
<!--              &lt;!&ndash;						</li>&ndash;&gt;-->
<!--              &lt;!&ndash;						<li>&ndash;&gt;-->
<!--              &lt;!&ndash;							<h4>МИНПРОМТОРГ РОССИИ</h4>&ndash;&gt;-->
<!--              &lt;!&ndash;							<p>Оказание услуг по экспертно-аналитическому сопровождению актуализации мето</p>&ndash;&gt;-->
<!--              &lt;!&ndash;						</li>&ndash;&gt;-->
<!--              &lt;!&ndash;						<li>&ndash;&gt;-->
<!--              &lt;!&ndash;							<h4>АППАРАТ ГОСУДАРСТВЕННОЙ ДУМЫ ФЕДЕРАЛЬНОГО СОБРАНИЯ РОССИЙСКОЙ ФЕДЕРАЦИИ</h4>&ndash;&gt;-->
<!--              &lt;!&ndash;							<p>Оказание услуг по проведению экспертно - аналитических исследований</p>&ndash;&gt;-->
<!--              &lt;!&ndash;						</li>&ndash;&gt;-->
<!--              &lt;!&ndash;						<li>&ndash;&gt;-->
<!--              &lt;!&ndash;							<h4>МИНПРОМТОРГ РОССИИ</h4>&ndash;&gt;-->
<!--              &lt;!&ndash;							<p>Экспертно-аналитическое сопровождение создания моделей построения кооперат</p>&ndash;&gt;-->
<!--              &lt;!&ndash;						</li>&ndash;&gt;-->
<!--            </ul>-->
<!--          </v-tab-item>-->

<!--        </v-tabs>-->

<!--      </div>-->
    </section>


    <section v-if="false">
      <h2>Проводки</h2>

      <table class="desktop-table">
        <thead>
        <tr>
          <th>
            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M3.31331 4.43333L2.37331 5.38L4.33331 7.33333L7.79331 3.88L6.85331 2.93333L4.33331 5.45333L3.31331 4.43333ZM8.33331 0H1.66665C0.933313 0 0.333313 0.6 0.333313 1.33333V12L4.99998 10L9.66665 12V1.33333C9.66665 0.6 9.06665 0 8.33331 0ZM8.33331 10L4.99998 8.54667L1.66665 10V1.33333H8.33331V10Z"
                  fill="#77809A"/>
            </svg>
          </th>
          <th>id проводки</th>
          <th>сумма</th>
          <th>дата изм.</th>
          <th>план/факт</th>
          <th>нал/безнал</th>
          <th>отв-ный</th>
          <th>орг–ция</th>
          <th>доход / расход</th>
          <th>фонд</th>
          <th>статья</th>
          <th>контрагент</th>
          <th>год по бюджету</th>
          <th>проект</th>
          <th>договор</th>
          <th>автор записи</th>
          <th>примечание</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><span class="dot red"></span></td>
          <td><a href="#">2932753641</a></td>
          <td>1 000 000</td>
          <td>02.07.2020</td>
          <td>План</td>
          <td>Безнал</td>
          <td><a href="#">Краснов С.М.</a></td>
          <td><a href="#">НИСИПП</a></td>
          <td>Доход</td>
          <td>Проектные расходы</td>
          <td>Сторонние соисполнители</td>
          <td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>
          <td>2020</td>
          <td><a href="#">БизнесПодКлюч2020</a></td>
          <td><a href="#">Договор 01/25/62-20-2 ...</a></td>
          <td><a href="#">Краснов С.М.</a></td>
          <td>Примечание</td>
          <td>
            <v-menu
                bottom
                left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>Редактировать</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>Удалить</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
        <tr>
          <td><span class="dot red"></span></td>
          <td><a href="#">2932753641</a></td>
          <td>1 000 000</td>
          <td>02.07.2020</td>
          <td>План</td>
          <td>Безнал</td>
          <td><a href="#">Краснов С.М.</a></td>
          <td><a href="#">НИСИПП</a></td>
          <td>Доход</td>
          <td>Проектные расходы</td>
          <td>Сторонние соисполнители</td>
          <td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>
          <td>2020</td>
          <td><a href="#">БизнесПодКлюч2020</a></td>
          <td><a href="#">Договор 01/25/62-20-2 ...</a></td>
          <td><a href="#">Краснов С.М.</a></td>
          <td>Примечание</td>
          <td>
            <v-menu
                bottom
                left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>Редактировать</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>Удалить</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
         <tr>
          <td><span class="dot red"></span></td>
          <td><a href="#">2932753641</a></td>
          <td>1 000 000</td>
          <td>02.07.2020</td>
          <td>План</td>
          <td>Безнал</td>
          <td><a href="#">Краснов С.М.</a></td>
          <td><a href="#">НИСИПП</a></td>
          <td>Доход</td>
          <td>Проектные расходы</td>
          <td>Сторонние соисполнители</td>
          <td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>
          <td>2020</td>
          <td><a href="#">БизнесПодКлюч2020</a></td>
          <td><a href="#">Договор 01/25/62-20-2 ...</a></td>
          <td><a href="#">Краснов С.М.</a></td>
          <td>Примечание</td>
          <td>
            <v-menu
                bottom
                left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>Редактировать</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>Удалить</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
         <tr>
          <td><span class="dot red"></span></td>
          <td><a href="#">2932753641</a></td>
          <td>1 000 000</td>
          <td>02.07.2020</td>
          <td>План</td>
          <td>Безнал</td>
          <td><a href="#">Краснов С.М.</a></td>
          <td><a href="#">НИСИПП</a></td>
          <td>Доход</td>
          <td>Проектные расходы</td>
          <td>Сторонние соисполнители</td>
          <td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>
          <td>2020</td>
          <td><a href="#">БизнесПодКлюч2020</a></td>
          <td><a href="#">Договор 01/25/62-20-2 ...</a></td>
          <td><a href="#">Краснов С.М.</a></td>
          <td>Примечание</td>
          <td>
            <v-menu
                bottom
                left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>Редактировать</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>Удалить</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
         <tr>
          <td><span class="dot red"></span></td>
          <td><a href="#">2932753641</a></td>
          <td>1 000 000</td>
          <td>02.07.2020</td>
          <td>План</td>
          <td>Безнал</td>
          <td><a href="#">Краснов С.М.</a></td>
          <td><a href="#">НИСИПП</a></td>
          <td>Доход</td>
          <td>Проектные расходы</td>
          <td>Сторонние соисполнители</td>
          <td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>
          <td>2020</td>
          <td><a href="#">БизнесПодКлюч2020</a></td>
          <td><a href="#">Договор 01/25/62-20-2 ...</a></td>
          <td><a href="#">Краснов С.М.</a></td>
          <td>Примечание</td>
          <td>
            <v-menu
                bottom
                left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>Редактировать</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>Удалить</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
         <tr>
          <td><span class="dot red"></span></td>
          <td><a href="#">2932753641</a></td>
          <td>1 000 000</td>
          <td>02.07.2020</td>
          <td>План</td>
          <td>Безнал</td>
          <td><a href="#">Краснов С.М.</a></td>
          <td><a href="#">НИСИПП</a></td>
          <td>Доход</td>
          <td>Проектные расходы</td>
          <td>Сторонние соисполнители</td>
          <td><a href="#">ООО «НПП «КИБЕРГРУППА»</a></td>
          <td>2020</td>
          <td><a href="#">БизнесПодКлюч2020</a></td>
          <td><a href="#">Договор 01/25/62-20-2 ...</a></td>
          <td><a href="#">Краснов С.М.</a></td>
          <td>Примечание</td>
          <td>
            <v-menu
                bottom
                left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                  <v-icon color="#77809A">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-title>Редактировать</v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-title>Удалить</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
        </tbody>
      </table>

    </section>


  </v-container>
</template>

<script>

import {loadDataToObject, userInOneOfRoles} from "@/modules/CommonUtils";

export default {
  name: 'HomeComponent',
  data() {
    return {
      applications: [],
      activeApplications: [],
      oldApplications: [],
      contracts: [],
      activeContracts: [],
      oldContracts: [],
      apiLoaded: false
    }
  },
  methods: {
    loadData: loadDataToObject,
    userInOneOfRoles,
    async getApplications() {
      let search
      if (this.userInOneOfRoles('ROLE_OFFER', 'ROLE_PARTNER', 'ROLE_ADMIN')) {
        search = 'offerExpert.id:' + this.$user.id
      } else if (this.userInOneOfRoles('ROLE_FORMAL')) {
        search = 'formalExpert.id:' + this.$user.id
      }
      await this.loadData('/supmain/applications?page=0&size=10&search=' + search, 'applications', false)
      for (let app of this.applications) {
        if (app.applicationStatus === 'NOTSTARTED' || app.applicationStatus === 'INPROGRESS' || app.applicationStatus === 'SENT') {
          this.activeApplications.push(app)
        } else {
          this.oldApplications.push(app)
        }
      }
    },
    async getContracts() {
      let search
      if (this.userInOneOfRoles('ROLE_OFFER', 'ROLE_PARTNER', 'ROLE_ADMIN')) {
        search = 'offerExpert.id:' + this.$user.id
      } else if (this.userInOneOfRoles('ROLE_FORMAL')) {
        search = 'formalExpert.id:' + this.$user.id
      }
      await this.loadData('/supmain/contracts?page=0&size=10&sort=id,desc&search=contractStatus:PROGRESS,' + search, 'activeContracts', false)
      await this.loadData('/supmain/contracts?page=0&size=100&sort=id,desc&search=' + search, 'oldContracts', false)

      this.oldContracts = this.oldContracts.filter(e=>e.contractStatus!=='PROGRESS').slice(0, 10)
    }
  },
  async beforeMount() {
    await Promise.all([
      this.getApplications(),
      this.getContracts()
    ])
    this.apiLoaded = true
    console.log(this.activeContracts)
  }
}
</script>
